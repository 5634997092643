<template>
  <v-dialog
    :content-class="$vuetify.breakpoint.mobile ? 'message-dialog-mobile' : ''"
    v-model="dialog"
    max-width="800"
    @keydown.esc="cancel"
    @click:outside="close()"
  >
    <v-card outlined v-if="message">
      <v-card-text class="pt-4" :class="{ 'grey darken-3': $vuetify.theme.dark }">
        {{ formatDateTime(message.sentOn) }}<br />
        <span>{{ message.fromUserFriendlyName }}</span>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-title class="headline word-break">{{ message.subject }}</v-card-title>
      <v-card-text class="my-4">
        <div class="message-content" v-html="formattedContent"></div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click.stop="close()"> {{ $t("t_close") }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Helpers from "@/mixins/helpers";
export default {
  name: "MessageDialog",
  mixins: [Helpers],

  computed: {
    formattedContent() {
      if (!this.message?.content) return "";
      return this.isHTML(this.message.content)
        ? this.message.content // Return original content if it's HTML
        : this.formatMessageContent(this.message.content); // Format if it's plain text
    },
  },

  data() {
    return {
      dialog: false,
      message: null,
    };
  },

  methods: {
    isHTML(str) {
      // Simple check for HTML tags
      const htmlRegex = /<[a-z][\s\S]*>/i;
      return htmlRegex.test(str);
    },

    formatMessageContent(content) {
      return content
        .split(/\n\s*\n/)
        .map(
          (paragraph) =>
            `<p>${paragraph
              .replace(/\n/g, "<br>")
              .replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>')}</p>`,
        )
        .join("");
    },

    open(activeMessage) {
      this.dialog = true;
      this.message = activeMessage;
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss">
.message-content {
  word-break: break-word;
  line-height: 1.5;

  p {
    margin: 0 0 1em 0;
    &:last-child {
      margin-bottom: 0;
    }
  }

  br {
    line-height: 1.5;
  }

  a {
    color: var(--v-primary-base);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
.message-dialog-mobile {
  transform: translateY(-26px) !important;
  height: 87% !important;
}
</style>
